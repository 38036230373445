import { CompletedToursDto, UpdateUserDto, UserProfileDto } from 'generated/graphql';
import { LoggedInUserStateModel } from '@modules/auth/store/loggedInUser.state';

export class LoginUser {
  static readonly type = '[Auth] Login User';

  constructor(
    public email: string,
    public password: string,
    public redirectUrl?: string | null
  ) {}
}

export class LogoutUser {
  static readonly type = '[Auth] Logout User';
  constructor(public error?: string | undefined) {}
}

export class UpdateUser {
  static readonly type = '[Auth] Update User';
  constructor(
    public updateUser: UpdateUserDto,
    public route?: string
  ) {}
}

export class UpdateUserProfile {
  static readonly type = '[Auth] Update User Profile';
  constructor(
    public userProfile: UserProfileDto,
    public route?: string
  ) {}
}

export class UpdateUserTour {
  static readonly type = '[Auth] Update User Tour';
  constructor(public completedToursDto: CompletedToursDto) {}
}

export class RefreshUserData {
  static readonly type = '[Auth] Refresh User Data from DB';
}

export class TokenLogin {
  static readonly type = '[Auth] Token Login';
  constructor(public token: string) {}
}

export class UpdateUserLoginState {
  static readonly type = '[Auth] Update User Login State';
  constructor(public payload: Partial<LoggedInUserStateModel>) {}
}

export class GoogleTokenLogin {
  static readonly type = '[Auth] Google Token Login';
  constructor(
    public authorizationCode: string,
    public courseId: string,
    public itemId: string,
    public redirectUrl: string
  ) {}
}
